import * as React from 'react';
import daveUrl from '../images/dave.jpg';

// components
import Layout from '../components/Layout';

// styles
import '../styles/index.scss';

export default function NotFoundPage() {

  return (
    <Layout path="/404" title="Missing Page / GwynevereRose.com" >
      <div className="page-not-found" >
        <h4>Hmm? Are you lost?</h4>
        <a href="/" >
          <img src={daveUrl} />
        </a>
        <a href="/" >Return Home</a>
      </div>
    </Layout>
  );
}
